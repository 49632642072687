import React, { useState } from "react"
import { graphql, Link } from "gatsby"
import ReactMarkdown from "react-markdown/with-html"
import Img from "gatsby-image"
import { Row, Col, Accordion, Card, useAccordionToggle } from "react-bootstrap"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PreviewCompatibleImage from "../components/PreviewCompatibleImage"

export const HowItWorksPageTemplate = props => {
  const [activeKey, setActiveKey] = useState(undefined)
  const {
    sectionIntro,
    expandableSection,
    principlesSection,
    wwdSection,
  } = props

  const CustomToggle = ({ children, eventKey }) => {
    const decoratedOnClick = useAccordionToggle(eventKey, eventKey => {
      const key = children.props.children[0].props.eventKey
      setActiveKey(key)
    })
    return <span onMouseOver={decoratedOnClick}>{children}</span>
  }

  return (
    <Layout>
      <SEO title="How It Works" />
      <Row className="mx-0" style={{ marginTop: "-73px" }}>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center HIW-landing-image">
            <div className="overlay"></div>
            <div style={{ height: "100vh" }}>
              <h1 className="sectionTitle">How it works</h1>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="d-flex flex-column align-items-center HIW-section2">
        <Col xs={10} lg={8}>
          <p>{sectionIntro.top}</p>
        </Col>
        <Col xs={12} lg={8} className="my-5">
          <PreviewCompatibleImage
            imageInfo={{
              image: sectionIntro.image,
              alt: "Lightschools image",
              className: "image",
            }}
          />
        </Col>
        <Col xs={10} lg={8} style={{ marginBottom: "20px" }}>
          <p>{sectionIntro.bottom}</p>
        </Col>
      </Row>
      <Row className=" HIW-dropdowns">
        <Col>
          <Accordion className="accordion" activeKey={activeKey}>
            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="0">
                  {expandableSection.expandable1.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        <ReactMarkdown
                          source={expandableSection.expandable1.description}
                          linkTarget="_blank"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>

            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1">
                  {expandableSection.expandable2.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        <ReactMarkdown
                          source={expandableSection.expandable2.description}
                          linkTarget="_blank"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="2">
                  {expandableSection.expandable3.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        <ReactMarkdown
                          source={expandableSection.expandable3.description}
                          linkTarget="_blank"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="3">
                  {expandableSection.expandable4.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="3">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: expandableSection.expandable4.description,
                          }}
                        ></div> */}
                        <ReactMarkdown
                          source={expandableSection.expandable4.description}
                          escapeHtml={false}
                          linkTarget="_blank"
                        />
                        {/* <Img
                          fluid={props.sustainable.childImageSharp.fluid}
                          className="image"
                          style={{ maxWidth: "1500px", margin: "auto" }}
                        /> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="4">
                  {expandableSection.expandable5.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="4">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        <ReactMarkdown
                          source={expandableSection.expandable5.description}
                          linkTarget="_blank"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
            <CustomToggle>
              <Card style={{ borderBottom: "none" }}>
                <Accordion.Toggle as={Card.Header} eventKey="5">
                  {expandableSection.expandable6.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="5">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        {/* <div
                          dangerouslySetInnerHTML={{
                            __html: expandableSection.expandable6.description,
                          }}
                        ></div> */}
                        <ReactMarkdown
                          source={expandableSection.expandable6.description}
                          linkTarget="_blank"
                        />
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
            <CustomToggle>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="6">
                  {expandableSection.expandable7.title}
                  <svg
                    class="bi bi-caret-down-fill"
                    width="0.6em"
                    height="0.6em"
                    viewBox="0 0 16 16"
                    style={{ marginLeft: "8px" }}
                    fill="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 01.753 1.659l-4.796 5.48a1 1 0 01-1.506 0z" />
                  </svg>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="6">
                  <Card.Body>
                    <Row className="d-flex flex-row justify-content-center">
                      <Col xs={10} lg={8} style={{ padding: 0 }}>
                        <ReactMarkdown
                          source={expandableSection.expandable7.description}
                          linkTarget="_blank"
                        />
                        {/* <Img
                          fluid={props.globalGraphic.childImageSharp.fluid}
                          className="image"
                          style={{ maxWidth: "1500px", margin: "auto" }}
                        /> */}
                      </Col>
                    </Row>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </CustomToggle>
          </Accordion>
        </Col>
      </Row>
      <Row className="HIW-section-principles">
        <div className="overlay-top"></div>
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle" id="principles">
                Principles
              </h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="HIW-section-principles-after d-flex justify-content-center align-items-center pt-5">
        <Col xs={10} lg={8}>
          <ReactMarkdown
            source={principlesSection}
            escapeHtml={false}
            linkTarget="_blank"
          />
        </Col>
      </Row>
      <Row className="HIW-section-numbers">
        <div className="overlay-top"></div>
        <Col>
          <Row style={{ marginTop: "30%" }} className="number-text-wrapper">
            <Col xs={12} sm={6} className="number-text">
              <div>1</div>
              EMPOWERMENT
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>2</div>
              TRUST & RESPECT
            </Col>
            <Col xs={12} sm={6} className="number-text">
              <div>3</div>
              AUTONOMY
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>4</div>
              AUTHENTICITY & OPENNESS
            </Col>
            <Col xs={12} sm={6} className="number-text">
              <div>5</div>
              LIFE-LEARNING
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>6</div>
              SELF-DEVELOPMENT
            </Col>
            <Col xs={12} sm={6} className="number-text">
              <div>7</div>
              HOLISTIC APPROACH
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>8</div>
              BALANCE & SUSTAINABILITY
            </Col>
            <Col xs={12} sm={6} className="number-text">
              <div>9</div>
              DEMOCRACY & INCLUSIVENESS
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>10</div>
              PLAY & FLEXIBILITY
            </Col>
            <Col
              xs={12}
              sm={6}
              className="number-text"
              style={{ letterSpacing: "-0.055em" }}
            >
              <div>11</div>
              COMPASSIONATE CONNECTION & CREATIVE COLLABORATION
            </Col>
            <Col xs={12} sm={6} className="number-text odd">
              <div>12</div>
              REFLECTION & FEEDBACK
            </Col>
            <Col xs={12} sm={6} className="number-text">
              <div>13</div>
              COMMITMENT TO THE ENTIRE ECOSYSTEM
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="HIW-section-whatWeDo">
        <Col className="px-0">
          <div className="w-100 d-flex justify-content-center align-items-center bg-image">
            <div className="overlay"></div>
            <div className="sectionTitleWrapper">
              <h1 className="sectionTitle" id="what-we-do">
                What we do
              </h1>
            </div>
          </div>
        </Col>
        <div className="overlay-bottom"></div>
      </Row>
      <Row className="py-5 d-flex flex-column mx-0 HIW section-because-lower align-items-center">
        <Col xs={12} lg={8}>
          <ReactMarkdown
            source={wwdSection.paragraph}
            escapeHtml={false}
            linkTarget="_blank"
          />
        </Col>
        <Col xs={12} lg={8}>
          <ul
            style={{ textAlign: "center", position: "relative" }}
            className="HIW-whatWeDo-list"
          >
            <span className="bg-text">What we do</span>
            {wwdSection.bottom.map(item => (
              <li>{item}</li>
            ))}
          </ul>
        </Col>
      </Row>
    </Layout>
  )
}

const HowItWorksPage = props => {
  const { frontmatter } = props.data.HowItWorksPageTemplate
  console.log(frontmatter)
  return (
    <HowItWorksPageTemplate
      title={frontmatter.title}
      sustainable={props.data.sustainable}
      sectionIntro={frontmatter.sectionIntro}
      expandableSection={frontmatter.expandableSection}
      principlesSection={frontmatter.principlesSection}
      wwdSection={frontmatter.wwdSection}
    />
  )
}

export default HowItWorksPage

export const pageQuery = graphql`
  query($id: String!) {
    HowItWorksPageTemplate: markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        sectionIntro {
          top
          image {
            childImageSharp {
              fluid(maxWidth: 1500) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          bottom
        }
        expandableSection {
          expandable1 {
            title
            description
          }
          expandable2 {
            title
            description
          }
          expandable3 {
            title
            description
          }
          expandable4 {
            title
            description
          }
          expandable5 {
            title
            description
          }
          expandable6 {
            title
            description
          }
          expandable7 {
            title
            description
          }
        }
        principlesSection
        wwdSection {
          bottom
          paragraph
        }
      }
    }
  }
`
